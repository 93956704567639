<script setup lang="ts">
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
    import type { CustomerQuery } from '~/graphql/generated-customer';
    const localPath = useLocalePathWoom();
    const { locale } = useLocales();

    // https://nuxt.com/docs/getting-started/data-fetching#client-only-fetching
    const { data, error } = await useFetch<CustomerQuery['customer']>(localPath('/customer'), {
        method: 'POST',
        key: 'customer',
        lazy: true,
        server: false,
    });

    const haveConfig = computed(() => {
        //
        if (locale.value === 'it_CH') return false;
        if (error.value?.statusCode === 421) return false;
        return Boolean(data.value || error.value);
    });
</script>
<template>
    <div
        v-if="haveConfig"
        class="atm-account-button">
        <popover
            v-if="data?.emailAddress?.emailAddress"
            class="relative"
            v-slot="{ open, close }">
            <popover-button
                :class="['relative h-6 w-6 hover:text-woom-red', { 'text-woom-red': open }]"
                type="button">
                <woom-icon-profile class="h-6 fill-current" />
                <span class="absolute bottom-0 right-0 h-2 w-2 rounded-full bg-woom-green"></span>
            </popover-button>
            <popover-panel
                class="absolute -right-8 top-10 z-lg rounded-lg bg-woom-white p-4 text-base drop-shadow-lg before:absolute before:-top-4 before:right-[30px] before:border-[16px] before:border-t-0 before:border-transparent before:border-b-woom-white md:-right-10 md:before:right-[38px]">
                <div class="border-b border-woom-grey-mid pb-4 hover:text-woom-red">
                    <nuxt-link-locale
                        to="/account"
                        @click="close">
                        <div
                            v-if="data.firstName"
                            class="first:font-bold">
                            {{ data.displayName }}
                        </div>
                        <div class="first:font-bold">{{ data.emailAddress.emailAddress }}</div>
                    </nuxt-link-locale>
                </div>
                <a
                    :href="localPath('/logout')"
                    class="flex h-12 items-center hover:text-woom-red"
                    @click="close">
                    <woom-icon-share class="mr-4 h-5 -rotate-90 fill-current" />
                    {{ $t('cta.account.logout') }}
                </a>
            </popover-panel>
        </popover>
        <a
            v-else
            :href="localPath('/login')"
            class="text-woom-red">
            <woom-icon-profile class="h-6 fill-current" />
            <span class="sr-only">{{ $t('cta.account.login') }}</span>
        </a>
    </div>
</template>
